import { isMobile } from '../utils/common';

const backgroundHero = {
    name: 'backgroundHero',
    hero: document.querySelector('.hero'),

    updateBackground() {
        const imageDesktop = this.hero.dataset.heroImage;
        const imageMobile = this.hero.dataset.heroImageMobile;

        if (imageDesktop && imageMobile) {
            if (isMobile()) {
                this.hero.style.backgroundImage = `url(${imageMobile})`;
            } else {
                this.hero.style.backgroundImage = `url(${imageDesktop})`;
            }
        }
    },

    init() {
        if (this.hero) {
            this.updateBackground();

            window.addEventListener('resize', () => this.updateBackground());
        }
    },
};

export default backgroundHero;