import Swiper from 'swiper';
import 'swiper/css/bundle';
import { Mousewheel } from 'swiper/modules';
import { isMobile } from '../utils/common';

const about = {
  name: 'about',
  sectionRef: null,
  swiper: null,

  init() {
    this.sectionRef = document.querySelector('.swiper');
    if (this.sectionRef) {
      this.setupSlider();
      this.bindEvents();
    }
    this.updateMobileState();
  },

  setupSlider() {
    this.swiper = new Swiper(this.sectionRef, {
      direction: "horizontal",
      modules: [Mousewheel],
      speed: 850,
      spaceBetween: 40,
      loop: false,
      freeMode: true,
      slidesPerView: 'auto',
      allowTouchMove: true,
      updateOnWindowResize: true,
      lazyPreloadPrevNext: 1,
      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
      breakpoints: {
        1000: {
          spaceBetween: 60,
          mousewheel: { enabled: false },
          freeMode: false,
          slidesPerView: 1.3,
        }
      },
    });
  },

  bindEvents() {
    ['mousemove', 'mouseenter', 'slideChange'].forEach(event => 
      this.sectionRef.addEventListener(event, () => this.updateSliderPosition())
    );
    this.sectionRef.addEventListener('mouseleave', () => this.resetSliderPosition());
    window.addEventListener('resize', () => this.onResize());
  },

  updateSliderPosition() {
    const activeIndex = this.swiper.activeIndex;
    const totalSlides = this.swiper.slides.length;
    this.sectionRef.classList.remove('left', 'right', 'middle');
    
    if (activeIndex === 0) {
      this.sectionRef.classList.add('right');
    } else if (activeIndex === totalSlides - 1) {
      this.sectionRef.classList.add('left');
    } else {
      this.sectionRef.classList.add('middle');
    }
  },

  resetSliderPosition() {
    this.sectionRef.classList.remove('left', 'right', 'middle');
  },

  updateMobileState() {
    if (this.swiper) {
      isMobile()? this.swiper.mousewheel.enable() : this.swiper.mousewheel.disable();
      this.swiper.update();
    }
  },

  onResize() {
    this.updateMobileState();
  }
};

export default about;