import { startLenis, stopLenis } from './constants';

const header = {
    name: 'header',
    logoIsDark: false,
    elements: {},

    init() {
        this.cacheDOM();
        this.bindEvents();
        this.onScroll();
        this.setupMobileMenu();
    },

    cacheDOM() {
        this.elements = {
            header: document.querySelector('.header'),
            logo: document.querySelector('.header-logo'),
            mobileMenu: document.querySelector('#mobile-menu'),
            burger: document.querySelector('#header-burger button'),
            links: document.querySelectorAll('.header a[href^="#"], .header a[href^="/#"], #mobile-menu a[href^="#"], #mobile-menu a[href^="/#"]'),
            sections: document.querySelectorAll('section')
        };
    },

    bindEvents() {
        window.addEventListener('scroll', () => this.onScroll());
        this.elements.links.forEach(link => link.addEventListener('click', (event) => this.scrollTo(event, link)));
    },

    onScroll() {
        for (const section of this.elements.sections) {
            const rect = section.getBoundingClientRect();
            if (rect.top <= 100 && rect.bottom > 100) {
                this.updateHeader(section);
                this.setActiveLink(section.id);
                break;
            }
        }
    },

    updateHeader(section) {
        const { header, logo } = this.elements;
        header.classList.remove('neutral', 'inverted');
        logo.classList.remove('dark');

        let headerClass = '';
        this.logoIsDark = false;

        if (section.classList.contains('hero')) {
            headerClass = 'neutral';
            this.logoIsDark = true;
        } else if (section.classList.contains('hero-simple')) {
            headerClass = 'neutral';
            this.logoIsDark = false;
        } else if (section.classList.contains('white') || section.classList.contains('frost')) {
            headerClass = 'inverted';
            this.logoIsDark = true;
        }

        if (headerClass) {
            header.classList.add(headerClass);
        }
        if (this.logoIsDark) {
            logo.classList.add('dark');
        }
    },

    setActiveLink(sectionId) {
        this.elements.links.forEach(link => {
            const linkHref = link.getAttribute('href').replace(/^#|^\/#/, '');
            link.classList.toggle('active', linkHref === sectionId);
        });
    },

    setupMobileMenu() {
        const { burger, mobileMenu, logo, header } = this.elements;

        burger.addEventListener('click', () => {
            const isExpanded = burger.getAttribute('aria-expanded') === 'true';
            burger.setAttribute('aria-expanded', (!isExpanded).toString());
            mobileMenu.setAttribute('aria-hidden', isExpanded.toString());

            if (!isExpanded) {
                this.logoIsDark = logo.classList.contains('dark');
                logo.classList.remove('dark');
                stopLenis();
                document.body.style.overflow = 'hidden';
                header.classList.add('opened');
            } else {
                startLenis();
                document.body.style.overflow = '';
                if (this.logoIsDark) {
                    logo.classList.add('dark');
                }
                header.classList.remove('opened');
            }
        });
    },

    scrollTo(event, link) {
        event.preventDefault();
        const targetId = link.getAttribute('href').replace(/^#|^\/#/, '');
        const target = document.getElementById(targetId);
        const offset = target.getBoundingClientRect().top + window.pageYOffset;

        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        });

        if (link.closest('#mobile-menu')) {
            const { burger } = this.elements;
            if (burger.getAttribute('aria-expanded') === 'true') {
                burger.click();
            }
        }
    }
};

export default header;