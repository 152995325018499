import { initWebgl, getWebgl } from './constants';

const animatedHero = {
    name: 'animatedHero',
    hero: document.querySelector('.hero'),
    video: document.querySelector('.hero-video'),

    init() {
        if (this.hero && this.video) {
            initWebgl();

            const webgl = getWebgl();
            if (webgl && !webgl.actif) {
                const canvas = document.querySelector('canvas') 
                if (canvas) {
                    canvas.classList.remove('off')
                }
            
                webgl.init()
                webgl.animate()
                webgl.actif = true
            }
        }
    },
};

export default animatedHero;