import SplitType from 'split-type';
import { gsapScrollTrigger } from './constants';
import { isSafari } from '../utils/common';

const animatedTexts = {
    name: 'animatedTexts',
    textContainers: document.querySelectorAll('[data-animation="text"]'),
    animations: [],
    splitInstances: [],

    animateText(container) {
        const split = new SplitType(container, { types: 'words,chars',  });
        this.splitInstances.push(split);
        
        return gsapScrollTrigger.from(split.chars, {
            scrollTrigger: {
                trigger: container,
                start: 'top+=130px bottom',
                end: 'top center',
                toggleActions: "restart none none reverse"
            },
            opacity: 0,
            y: 50,
            stagger: 0.009,
            duration: 1.2,
            ease: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
        });
    },

    setupAnimations() {
        if (isSafari()) {
            this.revealAllTexts();
        } else {
            this.animations = Array.from(this.textContainers).map(container => this.animateText(container));
        }
    },

    revealAllTexts() {
        this.textContainers.forEach(container => {
            gsapScrollTrigger.set(container, { opacity: 1, y: 0 });
        });
    },

    init() {
        this.setupAnimations();
        window.addEventListener('resize', () => this.handleResize());
    },

    handleResize() {
        clearTimeout(this.resizeTimeout);
        
        this.resizeTimeout = setTimeout(() => {
            this.cleanup();
            this.setupAnimations();
        }, 250);
    },

    cleanup() {
        this.animations.forEach(animation => {
            if (animation.scrollTrigger) {
                animation.scrollTrigger.kill();
            }
            animation.kill();
        });
        this.animations = [];

        this.splitInstances.forEach(split => split.revert());
        this.splitInstances = [];

        this.textContainers.forEach(container => {
            gsapScrollTrigger.set(container, { clearProps: "all" });
        });
    }
};

export default animatedTexts;