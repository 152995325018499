import { isMobile, isSafari } from '../utils/common';
import { gsapScrollTrigger } from './constants';

const animatedSections = {
    name: 'animatedSections',
    sections: document.querySelectorAll('[data-animation="clip"]:not([data-not-animated])'),
    animations: [],

    setupAnimations() {
        if (this.animations.length > 0) return;

        this.sections.forEach((section) => {
            const end = section.parentElement.id === 'contact' ? 'top-=50px bottom' : 'top center';

            const tl = gsapScrollTrigger.timeline({
                scrollTrigger: {
                    trigger: section,
                    start: 'top-=500px bottom',
                    end,
                    scrub: true,
                    onUpdate: (self) => section.parentElement.id === 'about' ? this.updateOverflow(section, self.progress) : null
                }
            });

            tl.set(section, { '--section-scale': '0.5' })
              .to(section, { '--section-scale': '1' });

            this.animations.push(tl);
        });
    },

    updateOverflow(section, progress) {
        const scale = progress;
        section.style.overflowX = scale < 1 ? 'hidden' : 'visible';
    },

    revealAllSections() {
        this.sections.forEach(section => {
            section.style.setProperty('--section-scale', 1)
            section.style.overflowX = 'visible'
        });
    },

    cleanupAnimations() {
        this.animations.forEach(animation => animation.kill());
        this.animations = [];
    },

    handleResize() {
        cancelAnimationFrame(this.resizeRAF);
        
        this.resizeRAF = requestAnimationFrame(() => {
            if (isMobile() || isSafari()) {
                this.cleanupAnimations();
                this.revealAllSections();
            } else {
                this.setupAnimations();
            }
        });
    },

    init() {
        if (isMobile() || isSafari()) {
            this.revealAllSections();
        } else {
            this.setupAnimations();
        }

        window.addEventListener('resize', () => this.handleResize());
    }
};

export default animatedSections;