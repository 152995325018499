export const enableTransitions = () => {
  window.addEventListener('load', () => {
    document.body.classList.remove('preload');
  });
};

export const isTouch = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

export const isMobile = () => window.innerWidth < 1000 || /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

export const isSafari = () => /Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent)