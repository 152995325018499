import axios from 'axios';

const forms = {
    name: 'forms',
    forms: document.querySelectorAll('form.js-form'),

    handleLabel(e, target) {
        const field = e ? e.target : target
        const parent = field.closest('.form-group')
        const label = parent.querySelector('label')
        const focused = field === document.activeElement


        if (!focused && !field.value) {
            label.classList.add('p5')
        } else {
            label.classList.remove('p5')
        }

        if (focused || field.value) {
            label.classList.add('p7')
            label.classList.add('label-float')
        } else {
            label.classList.remove('p7')
            label.classList.remove('label-float')
        }

        if (field.value) {
            field.classList.add('not-empty')
        } else {
            field.classList.remove('not-empty')
        }
    },

    validateField(e, target) {
        const field = e ? e.target : target
        const parent = field.closest('.form-group')
        const form = parent.closest('form')
        const fields = form.querySelectorAll('input:not([type="hidden"]), textarea:not(#g-recaptcha-response)')
        const submit = form.querySelector('button[type="submit"]')
        const errorEl = parent.querySelector('.error-message')
        let errorMsg = ''
        let valid = true

        if (field.required && !field.value) {
            if (field.getAttribute('data-required-error')) {
                errorMsg = field.getAttribute('data-required-error')
            } else {
                errorMsg = 'Ce champ est requis'
            }
            valid = false
        } else if (field.type === 'email' && !field.validity.valid) {
            if (field.getAttribute('data-email-error')) {
                errorMsg = field.getAttribute('data-email-error')
            } else {
                errorMsg = 'Ce champ doit être une adresse email valide'
            }
            valid = false
        }

        if (!valid) {
            parent.classList.add('has-error')
            errorEl.innerHTML = errorMsg
        } else {
            parent.classList.remove('has-error')
            errorEl.innerHTML = ''
            errorMsg = ''
        }

        submit.disabled = false
        fields.forEach(field => {
            const parent = field.closest('.form-group')
            const fieldValid = !parent.classList.contains('has-error')

            if (!fieldValid) {
                submit.disabled = true
            }
        })
    },

    validateForm(form) {
        const fields = form.querySelectorAll('input:not([type="hidden"]), textarea:not(#g-recaptcha-response)')
        let valid = true

        fields.forEach(field => {
            this.validateField(null, field)

            const parent = field.closest('.form-group')
            const fieldValid = !parent.classList.contains('has-error')

            if (valid && !fieldValid) {
                valid = false
            }
        })

        return valid
    },

    submit(e) {
      e.preventDefault()

      const form = e.target

      if (this.validateForm(form)) {
        console.log('valid')

        const formData = new FormData(form);
        const url = form.querySelector('input[name="action"]').value;
    
        axios.post(url, formData, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
        .then(() => {
            form.reset()
            form.classList.remove('active')
            const successEl = form.closest('.contact-form-wrapper').querySelector('.contact-success')
            successEl.classList.add('active')
        })
        .catch(error => {
            console.error('Erreur:', error);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            alert('Une erreur est survenue lors de l\'envoi du formulaire. Veuillez réessayer.');
        });
      }

      return false
    },

    backToForm(e) {
      e.preventDefault()

      const timestamp = new Date().getTime();

      const origin = window.location.origin;
      window.location.replace(`${origin}/?t=${timestamp}#contact`); 
    },

    cleanUrl() {
        const url = new URL(window.location.href);
        if (url.searchParams.has('t')) {
            url.searchParams.delete('t');
            window.history.replaceState({}, document.title, url.toString());
        }
    },
    
    init() {
        this.forms.forEach(form => {
            const fields = form.querySelectorAll('input:not([type="hidden"]), textarea')
            fields.forEach(field => {
                field.addEventListener('input', (e) => this.validateField(e, null))
                field.addEventListener('focus', (e) => this.handleLabel(e, null))
                field.addEventListener('blur', (e) => this.handleLabel(e, null))
            })

            const submitBtn = form.querySelector('button[type="submit"]')
            submitBtn.disabled = true

            form.addEventListener('submit', this.submit.bind(this))

            const returnFormBtn = form.closest('.contact-form-wrapper').querySelector('.contact-success button')
            returnFormBtn.addEventListener('click', this.backToForm)
        })

        this.cleanUrl()
    }
}

export default forms