// app.js
import '@/styles/app.scss';

import { enableTransitions } from './utils/common';
import sayHello from './modules/hello';
import { initLenis, startLenis } from './modules/constants';
import header from './modules/header';
import animatedSections from './modules/animated-sections';
import animatedTexts from './modules/animated-texts';
import animatedHero from './modules/animated-hero';
import backgroundHero from './modules/background-hero';
import about from './modules/about';
import services from './modules/services';
import forms from './modules/forms';

// App main
const main = async () => {
  if (import.meta.env.DEV) {
    sayHello.init()
  }

  enableTransitions()

  initLenis();
  startLenis();

  header.init()
  animatedSections.init()
  animatedTexts.init()
  animatedHero.init()
  backgroundHero.init()
  about.init()
  services.init()
  forms.init()

};

// Execute async function
main().then(() => {
  console.log();
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}