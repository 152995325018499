const services = {
    name: 'services',
    grid: document.querySelector('.services-grid'),
    items: document.querySelectorAll('.services-item'),

    setFixedColumnWidth() {
        if (this.grid && this.items.length > 0) {
            const firstColumnWidth = this.items[0].offsetWidth;
            this.grid.style.setProperty('--fixed-column-width', `${firstColumnWidth}px`);
        }
    },

    handleItemHover(hoveredItem, isEntering) {
        this.items.forEach(item => {
            item.classList.toggle('active', isEntering && item === hoveredItem);
            item.classList.toggle('not-active', isEntering && item !== hoveredItem);
        });
    },

    setupEventListeners() {
        const handleEnter = item => () => this.handleItemHover(item, true);
        const handleLeave = () => this.handleItemHover(null, false);

        this.items.forEach(item => {
            item.addEventListener('mouseenter', handleEnter(item));
            item.addEventListener('mouseleave', handleLeave);
        });

        window.addEventListener('resize', () => {
            requestAnimationFrame(() => this.setFixedColumnWidth());
        });
    },

    init() {
        if (this.grid && this.items.length > 0) {
            this.setFixedColumnWidth();
            this.setupEventListeners();
        }
    }
};

export default services;