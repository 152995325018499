// constants.js
import { isTouch } from '../utils/common';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'
import { Webgl } from '../utils/webgl'

gsap.registerPlugin(ScrollTrigger)

export const gsapScrollTrigger = gsap

let lenisInstance = null;
let webglInstance = null;

export const initLenis = () => {
  if (!isTouch() && !lenisInstance) {
    lenisInstance = new Lenis();
    lenisInstance.stop();

    const raf = (time) => {
      lenisInstance.raf(time);
      requestAnimationFrame(raf);
    };

    requestAnimationFrame(raf);
  }
};

export const getLenis = () => lenisInstance;

export const startLenis = () => {
  if (lenisInstance) {
    lenisInstance.start();
  }
};

export const stopLenis = () => {
  if (lenisInstance) {
    lenisInstance.stop();
  }
};

export const initWebgl = () => {
  if (!webglInstance) {
    webglInstance = new Webgl();
  }
};

export const getWebgl = () => webglInstance;